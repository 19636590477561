.slider {
    position: relative;
    width: 100%;
    height: 70vh;
    margin-bottom: 40px;
    overflow: hidden;
    white-space: nowrap;
  }
  .slider .slider_wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .slider_wrapper .slide {
    display: inline-block;
    height: 100%;
    width: 100%;
    position: relative;
  }
  .slide_content h1{
    font-weight: 900;
    font-size: 2.7rem;
  }
  
  .slide .slide_content {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(0%, -50%);
    z-index: 888;
    width: 38%;
    display: block;
    white-space: normal;
  }
  .slide:nth-child(2) .slide_content h1{
     color:#fff !important;
  }
  .slide:nth-child(2) .slide_content{
    color:#c7c7c7;
 }
  .slide .slide_content button {
    padding: 15px 30px;
    margin-top: 30px;
    background-color: var(--primary-color);
    color: var(--primary-white);
    border-radius: 5px;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease-in;
  }
  .slide .slide_content button:hover {
    background-color: var(--primary-color-light);
    color: var(--primary-black);
  }

  .dots_wrapper{
    display: none;
  }
/*
  remove this comment and display:none from above to show dots

  .slider .dots_wrapper {
    z-index: 999;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  .dots_wrapper .dot {
    color: rgb(24, 23, 23);
    font-size: 40px;
    font-weight: 700;
    cursor: pointer;
  }
  .dots_wrapper .dot.active {
    color:var(--primary-color);
  }
  */