.dropdown-menu {
    padding:0px;
}
.dropdown-item:focus, .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active {
    color:var(--primary-font);
    text-decoration: none;
    background-color: var(--primary-color-light);
}

a{
    color: var(--font-light);
 }

.home .info{
  text-align: center;
  margin-bottom: 50px;
}
.home .info p{
    margin-bottom: 3px;
}
.home .info span{
    color: #66ABC9;
    margin-top: -5px;
}
.main-service-div {
    margin-bottom: 30px;
    border-radius: 10px;
}
.main-service-div:nth-child(2){
    margin-top: 20px;   
 }
.main-service-div:nth-child(3){
    margin-top: 40px;   
 }
.main-service-div a{
  color: var(--font-light);
  display: inline-block;
  padding: 20px;
  border-radius: 10px;
}
.main-service-div a:hover{
    text-decoration: none;
    box-shadow: var(--shadow);
}
.main-service-div:nth-child(1) .bg-img{
    background-image: url(../images/img3.png);
}
.main-service-div:nth-child(2) .bg-img{
    background-image: url(../images/img2.png);
}
.main-service-div:nth-child(3) .bg-img{
    background-image: url(../images/img1.png);
}
.whyus-content:nth-child(1) .bg-img{
    background-image: url(../images/img5.png);
}
.whyus-content:nth-child(2) .bg-img{
    background-image: url(../images/img4.png);
}
.main-service-div p{
    padding-right: 10px;
}
.main-service-div .bg-img{
    background-color: var(--primary-color-light);
    width: 100%;
    height: 250px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .whyus-div{
    background-color: var(--primary-light);
    margin: 80px auto;
    position: relative;
  }
.whyus-div .whyus-content{
   margin-top: -40px ;
   display: inline-block;
   width: 100%;
}
.whyus-div .whyus-content:nth-child(2){
    margin-bottom: -40px;
    margin-top: auto;
}
  .whyus-content-div .bg-img{
    background-color: var(--primary-color-light);
    width: 100%;
    height: 350px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .whyus-content-div .detail{
    padding-top: 70px;
}

.video-div{
    margin-bottom: 50px;
}
.video-div iframe{
    width: 100%;
    height: 280px;
}
.home .bg-img {
    transition: background-size 0.3s;
}
.home .bg-img:hover {
    background-size: 105%;
    transition: background-size 0.3s;
}
.bg-pattern{
    position: absolute;
    bottom: 0%;
    left: 0;
    margin-left: -6rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.opacity-1 {
    opacity: 0.1;
}
.rotate-74 {
    -webkit-transform: rotate(74deg);
    transform: rotate(74deg);
}
.fill-color {
    fill: var(--primary-color);
}
.ms-n7 {
    margin-left: -6rem;
}
.whyus-content-div{
    z-index: 2;
    position: relative;
}
.whyus-content-div .f-circle{
    position: absolute;
    bottom: -50px;
    left: -50px;
    z-index: -1;
}
.f-circle{
    fill: var(--primary-color);
    opacity: 0.05;
}
.whyus-content:nth-child(2) .f-circle{
    top: -50px;
}



/********************************* Menu *******************************/
.mini_menu{
    display: none;
    position: absolute;
    right: 220px;
    top: -1px;
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
}
.mini_nav:hover > .mini_menu{
    display: block;
}


/*********************************POP UP*********************************/


.overlay {
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 999;
}
.overlay .popup {
    background: var(--primary-light);
    padding: 10px;
    margin: auto;
    border-radius: 20px;
    box-shadow: 0px 0px 10px #dbdbdb;
    position: relative;
}
.popup a {
    font-size: 1.1rem;
    display: inline-block;
    color: var(--font-light) ;
}
.popup a:hover {
    text-decoration: none;
    color: var(--font-light) ;
    margin-left: 0px;
}
.popup img {
    width: 70px;
    animation: shake 1s infinite;
}
@keyframes shake{
    0% { transform: translate(1px, 1px) rotate(0deg); }
    50% {  transform: translate(1px, 1px) rotate(0deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, 1px) rotate(0deg); }
}
.whatsapp_tip h5{
    font-size: 16px;
    color: var(--primary-font);
}
.whatsapp_tip p{
    font-size: 14px;
}
.popup .whatsapp_tip {
    z-index: -1;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #fff;
    width: 60px;
    height: 60px;
    padding: 10px;
    border-radius: 10px;
    transition: 0.3s;
    overflow: hidden;
}
.popup:hover .whatsapp_tip{
    bottom: 100px;
    width: 300px;
    min-height: 100px;
    transition: 0.3s;
}