@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

:root {
  --primary-color: #4D3B8E;
  --primary-color-light:#BFE3E8;
  --primary-black: #141416;
  --primary-white: #ffffff;
  --primary-light: #F1F6FE;
  --primary-font: #212124;
  --font-light: #66686F;
  --highlight: #f4f9d7;
  --gradient: linear-gradient(145deg, #e2e8ec, #ffffff);
  --shadow: 5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 15px;
  text-align: justify;
  background-color: var(--primary-white);
  color: var(--font-light);
}
h1, h2, h3, h4, h5{
  color: var(--primary-font);
  font-weight: 600;
}

/* Code for Firefox */
::-moz-selection {
  color: var(--primary-color);
  background: var(--highlight);
}
::selection {
  color: var(--primary-color);
  background: var(--highlight);
}

.form-control:focus{
  border-color: #fff;
  box-shadow: none;
}

button{
  font-weight: 600 !important;
  letter-spacing: 1px;
}

.navbar-expand-sm .navbar-nav .nav-link {
  padding-left: 1.5rem;
  padding-right:0rem;
  font-weight: 600;
}

.logo{
  width: 50px;
}

a{
  color: var(--primary-color-light);
}

figure{
  pointer-events: none;
}

.my-btn{
  font-weight: 600 !important;
  letter-spacing: 1px;
  background-color: var(--primary-color);
  color: var(--primary-white);
  padding: 10px 25px;
}
.my-btn:hover{
  background-color: var(--primary-color-light);
  color: var(--primary-black);
}

.bg-img{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 1400px){
.container {
    max-width: 1320px;
}
}