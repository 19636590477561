.contact{
 margin-bottom: 60px;
}
.contact-info{
    box-shadow: var(--shadow);
    padding: 40px 30px;
    border-radius: 10px;
}
.contact-info h3{
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-size: 20px;
}
.contact-info h5{
    color: var(--font-light);
    font-size: 18px;
}
.contact-info li{
    margin-bottom: 30px;
}
.contact-info a{
    color: var(--font-light);
}
.contact-info-div{
    padding-top: 50px;
}
.contact-info-div .contact-info{
    text-align: center;
    min-height: 240px;
    position: relative;
    overflow: hidden;
}
.contact-info-div .contact-info .f-circle{
    position: absolute;
    bottom: -55px;
    right: -10px;
}
.contact-info-div .contact-info .whatsapp{
    font-size: 18px;
    color: #14b509;
}
.contact .social{
    width: 100%;
}
.contact .social ul{
    justify-content: center;
}
.contact .social li a {
    padding: 15px 20px;
    margin: 30px 20px;
    box-shadow: var(--shadow);
    border-radius: 10px;
    display: inline-block;
    background-color: #f6f5f9;
    color: var(--primary-color);
    font-size: 18px;
}
.contact .social li a:hover {
    background-color: var(--primary-color);
    color: var(--primary-white);
}