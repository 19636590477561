.training .popup{
    margin: 90px auto;
    text-align: center;
}
.training .popup a {
    font-size: 1.4rem;
    margin-top: 20px;
}
.training .popup p {
    margin-bottom: 10px;
}

.solution .whyus-content .row{
   padding-top:30px;
}
.solution .whyus-content .row:nth-child(even){
    flex-direction: row-reverse;
}

.about-job{
    text-align: center;
    padding-bottom: 70px;
}