footer{
    background-color: var(--primary-color);
    color: var(--primary-light);
  }
  footer h5{
    color: var(--primary-white);
  }
  footer .btn{
    background-color: var(--primary-color-light);
    border-radius: 0px 5px 5px 0px;
  }
  footer .btn:hover{
    background-color: var(--primary-black);
    color: var(--primary-white);
  }
footer .form-control{
    border-radius: 5px 0px 0px 5px;
    height: calc(2em + 0.75rem + 2px);
}
footer a{
    color: var(--primary-white);
}
footer a:hover{
   margin-left: 4px;
   transition: 0.3s;
   color: var(--primary-color-light);
}