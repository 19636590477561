.bg-img-div{
    position: relative;
    margin-bottom: 60px;
    text-align: center;
}
.bg-img-div h3{
    color:#fff;
    margin-bottom: 20px;
}
.bg-img-div p{
    color:#ececec;
}
.bg-img-div .bg-img::before {
    top: 0;
    left: 0;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #4d495a;
    opacity: 0.5;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.bg-img-div .bg-img{
    background-image: url(../images/slider1.png);
    height: 300px;
    filter: blur(6px);
  -webkit-filter: blur(6px);
}
.job-heading{
    position: absolute;    
    width: 100%;
    top: 50%;
    transform: translate(0%, -50%);
}
.job-heading span{
    color: var(--primary-font-light);
}
.my-btn-2 {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    margin-right: 20px;
    color: var(--primary-color);
}
.my-btn-2:hover {
    background-color:  var(--primary-color-light);
    border: 1px solid var(--primary-color-light);
    margin-right: 20px;
}
.breadcrumb {
    background-color: transparent;
    padding: 0%;
}
.breadcrumb-item.active {
    color:var(--primary-color);
}
.accordion{
    margin: 20px auto 50px auto;
}
.card{
    background-color: var(--primary-light);
}
.card .btn-link{
    color: var(--primary-font);
}
.card .collapse{
    background-color: var(--primary-white);
}
.btn.focus, .btn:focus {
    box-shadow: none;
}
.card .btn:hover{
    color:var(--primary-color);
    text-decoration: none;
}