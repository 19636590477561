
@media (max-width: 1080px) {
  
  }
  
@media (max-width: 991px) {
   .container{
        max-width: initial;
    }
    h3{
        font-size: 22px;
    }
  }
  
@media (max-width: 767px) {
    .slide .slide_content {
        width:80%;
    }
    .slide_content h1, .detail h3, h5{
        text-align: left;
    }
    .collapse.show .navbar-nav .dropdown-menu {
        border: transparent;
        padding-left: 10px;
    }
    .collapse.show .dropdown-item {
        border-bottom: 1px solid #f1f1f1;
    }
    .mini_menu{
        display: block;
        position: relative;
        right: auto;
        top: auto;
        background-color: #fff;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: 0.25rem;
    }
  }
  
@media (max-width: 575px) {
    
    .main-service-div:nth-child(2), .main-service-div:nth-child(3) {
        margin-top: -35px;
    }
    .justify-content-end {
        flex-direction: column-reverse;
    }
    .whyus-content-div .detail {
        padding-top: 0px !important;
        padding-bottom: 40px !important;
    }
    .whyus-div .whyus-content:nth-child(2) {
        margin-bottom: 0px !important;
    }
    footer .justify-content-between{
        display: block !important;
    }
    .overlay .popup h5{
        font-size: 15px;
    }
    .overlay .popup p{
        font-size: 14px;
    }
    .my-btn-2 {
        margin-right: 6px;
    }
 
  }